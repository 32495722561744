<template>
  <div>
    <search-agent-withdrawals @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="showNewModal"
          v-if="isShowNewMenu()"
        >记录提现</a-button>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <img
        slot="payorderpic"
        slot-scope="text, record"
        v-if="record.pay_order_url"
        :src="record.pay_order_url"
        style="width:50px;"
        alt=""
        @click="handlePreview(record.pay_order_url)"
      >

      <template slot="applypic" slot-scope="text, record">
        <a
          v-if="isShowMenu('agent_files/download') && record.invoice_url"
          :href="record.invoice_url"
          target="_blank"
          download
        >
          发票
        </a>
        <img
          v-if="record.front_identity_card_url"
          :src="record.front_identity_card_url"
          style="width:50px;"
          alt=""
          @click="handlePreview(record.front_identity_card_url)"
        >
        <img
          v-if="record.back_identity_card_url"
          :src="record.back_identity_card_url"
          style="width:50px;"
          alt=""
          @click="handlePreview(record.back_identity_card_url)"
        >
      </template>

      <span slot="status" slot-scope="text, record">
        <span :class="'status-' + record.status_raw">{{ record.status }}</span>
      </span>

      <span slot="remark" slot-scope="text, record">
        <span :class="'remark-' + record.status_raw">{{ record.remark }}</span>
      </span>

      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a :disabled="record.status_raw=='paid'" @click="showReviewModal(record.id, record.amount)" v-if="isHasWithdrawalPermission">审核</a>
        </a-space>
      </span>
    </a-table>

    <!-- 记录提现模态框 -->
    <new-agent-withdrawal
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <preview-image
      :visible.sync="previewVisible"
      :image.sync="previewImage"
    />

    <!-- 审核 -->
    <agent-withdrawal-review
      v-if="isShowReviewModal"
      :visible.sync="isShowReviewModal"
      :withdrawal-id="reviewingWithdrawalId"
      :withdrawal-amount="withdrawalAmount"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchAgentWithdrawals from '@/views/withdrawals/agent/Search'
import PreviewImage from '@/components/PreviewImage'
import { hasPermission } from '@/utils/permission'
import NewAgentWithdrawal from '@/views/withdrawals/agent/New'
import { findAgentWithdrawals, exportAgentWithdrawals } from '@/api/withdrawal'
// import { getOriginalUserUid } from '@/utils/token'
import { formatCurrency } from '@/utils/filter'
import { exportExcel } from '@/api/excel'

export default {
  name: 'AgentWithdrawalList',
  components: {
    NewAgentWithdrawal,
    SearchAgentWithdrawals,
    PreviewImage,
    Pagination,
    AgentWithdrawalReview: () => import('@/views/withdrawals/agent/Review')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowNewModal: false,
      isShowReviewModal: false,
      reviewingWithdrawalId: 0, // 正在审核的申请单ID
      withdrawalAmount: 0.00, // 正在审核的申请单提现金额
      previewVisible: false,
      previewImage: '',
      pagination: {
        total_count: 0
      },
      query: {
      }
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    },

    columns() {
      return [
        {
          title: '事件批次',
          width: 100,
          dataIndex: 'no'
        },
        {
          title: '金额(元)',
          width: 100,
          customRender: formatCurrency,
          dataIndex: 'amount'
        },
        {
          title: '支付方式',
          width: 100,
          dataIndex: 'pay_way'
        },
        {
          title: '打款信息',
          width: 200,
          customRender: (record, index) => {
            return `${record.account_name} ${record.bank_name} ${record.bank_no}`
          }
        },
        {
          title: '申请资料',
          width: 200,
          scopedSlots: { customRender: 'applypic' }
        },
        {
          title: '状态',
          width: 100,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '说明',
          width: 100,
          dataIndex: 'remark',
          scopedSlots: { customRender: 'remark' }
        },
        {
          title: '支付凭据',
          width: 100,
          dataIndex: 'pay_order_url',
          scopedSlots: { customRender: 'payorderpic' }
        },
        {
          title: '提交时间',
          width: 200,
          dataIndex: 'created_at'
        },
        {
          title: '操作',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    isShowNewMenu() {
      return false
      // 有权限 && 不是超级登录
    //   return this.isShowMenu('agents/withdrawals/new') && !getOriginalUserUid()
    },

    isHasWithdrawalPermission() {
      return hasPermission('agent_bills/withdrawal_review')
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showReviewModal(recordId, withdrawalAmount) {
      this.isShowReviewModal = true
      this.reviewingWithdrawalId = recordId
      this.withdrawalAmount = withdrawalAmount
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findAgentWithdrawals(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    handlePreview(imageURL) {
      this.previewImage = imageURL
      this.previewVisible = true
    },

    exportData() {
      exportAgentWithdrawals(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download === true) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>

<style>
    .status-reject {
      background-color: #e64340;
      color: #fff;
    }

    .status-paid {
      background-color: #42b983;
      color: #fff;
    }

    .status-unpaid {
      background-color: #57a1e6;
      color: #fff;
    }

    .remark-reject {
      color: #e64340;
    }

    .remark-paid {
      color: #42b983;
    }

    .remark-unpaid {
      color: #57a1e6;
    }
</style>>
